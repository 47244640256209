import { navigate } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import {
  DEFAULT_PURCHASE_TYPE,
  DEFAULT_SHIPPING_INTERVAL,
  PURCHASE_TYPE_SUBSCRIPTION,
} from "../../constants"
import { useStore } from "../../hooks/useStore"
import { useToggle } from "../../hooks/useToggle"
import { getAttributeByKey } from "../../utils/getLineItemAttributes"
import { getProductByStorefrontId } from "../../utils/getProductByStorefrontId"
import { getProductOptions } from "../../utils/getProductOptions"
import { getDiscountPriceRaw } from "../../utils/money"
import { parseGid } from "../../utils/parseGid"
import { variantForOptions } from "../../utils/variantsForOptions"
import ButtonAddToCart from "../Buttons/ButtonAddToCart"
import UpsellModal from "../UpsellModal/UpsellModal"
import ProductOptions from "./ProductOptions"
import PurchaseOptions from "./PurchaseOptions"
import {
  StyledBuyBox,
  StyledRatingsButton,
  StyledRatingsContainer,
} from "./StyledBuyBox"
import {
  StyledDropdownSelect,
  StyledDropdownWrap,
  StyledFormFieldGroup,
} from "./StyledFormFields"

function handleClick() {
  document.getElementById("looxReviews").scrollIntoView({ behavior: "smooth" })
}

function BuyBoxSupplement({
  buttonText,
  children,
  showQuantity,
  discountCode,
  variant: DEFAULT_VARIANT,
}) {
  const graphqlResult = useStaticQuery(graphql`
    {
      allWpPage(
        filter: {
          productTypes: { nodes: { elemMatch: { slug: { eq: "supplement" } } } }
        }
      ) {
        nodes {
          id
          uri
          title
        }
      }
    }
  `)

  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.src = "//loox.io/widget/loox.js?shop=wildearthpets.myshopify.com"

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const pages = (graphqlResult?.allWpPage?.nodes || []).map(
    ({ uri, title }) => ({
      label: title,
      value: uri,
    })
  )

  const { addedVariantIds, products } = useStore()

  // gracefully handle legacy gutenberg redux api
  const product = getProductByStorefrontId(
    DEFAULT_VARIANT?.productId || DEFAULT_VARIANT?.product?.storefrontId,
    products
  )

  const availableOptions = getProductOptions(product)
  const [options, setOptions] = useState([])
  const [purchaseType, setPurchaseType] = useState(DEFAULT_PURCHASE_TYPE)

  const onetimeVariant = variantForOptions(product, options)

  const subscriptionVariant = {
    // this metafield attached to the onetime variant of this product is automatically updated by ReCharge
    price: getAttributeByKey(onetimeVariant.metafields)(
      "discount_variant_price"
    ),
  }

  const [variant, setVariant] = useState(onetimeVariant)
  const [shippingInterval, setShippingInterval] = useState(
    DEFAULT_SHIPPING_INTERVAL
  )

  const [modalVisible, handleModalVisibility] = useToggle()

  useEffect(() => {
    setVariant(onetimeVariant)
  }, [onetimeVariant])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: product.title,
            brand: product.vendor,
            offers: [
              {
                priceCurrency: "USD",
                price: onetimeVariant.price,
                sku: onetimeVariant.sku,
                name: product.title,
                identifier: onetimeVariant.shopifyId,
              },
              {
                priceCurrency: "USD",
                price: discountCode
                  ? getDiscountPriceRaw(
                      subscriptionVariant.price,
                      discountCode.value
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : subscriptionVariant.price,
                sku: variant.sku,
                name: product.title,
                identifier: `gid://shopify/ProductVariant/${getAttributeByKey(
                  onetimeVariant.metafields
                )("discount_variant_id")}`,
              },
            ],
          })}
        </script>
      </Helmet>
      {modalVisible && addedVariantIds?.length ? (
        <UpsellModal
          on={modalVisible}
          toggle={handleModalVisibility}
          variant={Object.assign({}, variant, { image: product.featuredImage })}
          purchaseType={purchaseType}
        />
      ) : null}
      <StyledBuyBox>
        <StyledRatingsContainer>
          <StyledRatingsButton onClick={handleClick}>
            <div
              className="loox-rating"
              data-fetch
              data-id="4522565304363"
              data-limit="10"
            ></div>
          </StyledRatingsButton>
        </StyledRatingsContainer>
        <StyledFormFieldGroup>
          <legend>Supplement Options</legend>
          <StyledDropdownWrap>
            <StyledDropdownSelect
              defaultValue={
                pages.find(
                  p =>
                    p.label ===
                    (DEFAULT_VARIANT?.label ||
                      DEFAULT_VARIANT?.displayName
                        .replace("Dog ", "")
                        .replace(` - ${DEFAULT_VARIANT.title}`, ""))
                )?.value
              }
              onChange={e => navigate(`${e.target.value}`)}
            >
              {pages.map(({ label, value }) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                )
              })}
            </StyledDropdownSelect>
          </StyledDropdownWrap>
        </StyledFormFieldGroup>
        {availableOptions && availableOptions.length ? (
          <ProductOptions
            options={options}
            availableOptions={availableOptions}
            handleChangeOption={e => {
              const { name, value } = e.target
              const selectedOptions = { ...options, [name]: value }

              setOptions(selectedOptions)
              setVariant(variantForOptions(product, selectedOptions))
            }}
          />
        ) : null}
        <PurchaseOptions
          purchaseType={purchaseType}
          handleChangePurchaseType={e => {
            setPurchaseType(e.target.value)
            setVariant(variantForOptions(product, options))
          }}
          shippingInterval={shippingInterval}
          handleChangeShippingInterval={e => {
            if (purchaseType === PURCHASE_TYPE_SUBSCRIPTION) {
              setShippingInterval(e.target.value)
            }
          }}
          subscriptionDiscount={discountCode}
          onetimeVariant={onetimeVariant}
          subscriptionVariant={subscriptionVariant}
        />
        {children}
        <ButtonAddToCart
          isSubscription={purchaseType === PURCHASE_TYPE_SUBSCRIPTION}
          showQuantity={showQuantity}
          text={buttonText}
          variant={variant}
          product={product}
          purchaseType={purchaseType}
          interval={shippingInterval}
          discountCode={discountCode}
          onAfterAction={handleModalVisibility}
          __dataLayer={{
            ProductTitle: product.title,
            ProductCategory: product.productType,
            ProductID: parseGid(product.shopifyId),
            ProductGID: product.shopifyId,
            PurchaseType: purchaseType,
            VariantName: variant.displayName,
            VariantID: parseGid(variant.shopifyId),
            VariantGID: variant.shopifyId,
            VariantSKU: variant.sku,
            VariantValue:
              purchaseType === PURCHASE_TYPE_SUBSCRIPTION
                ? subscriptionVariant.price
                : variant.price,

            product_ids: [parseGid(product.shopifyId)],
            product_type: "product_group",
            product_category: product.productType,
            product_name: product.title,
            line_item_value:
              purchaseType === PURCHASE_TYPE_SUBSCRIPTION
                ? subscriptionVariant.price
                : variant.price,
          }}
        />
      </StyledBuyBox>
    </>
  )
}

export default BuyBoxSupplement
