import PropTypes from "prop-types"
import * as React from "react"
import { Intercom } from "react-live-chat-loader"
import styled from "styled-components"
import { Container } from "../components/Container"
import Footer from "../components/Footer"
import Header from "../components/header"
import "./fonts.css"
import GlobalStyles from "./GlobalStyles"

const IntercomFacade = styled.div`
  > div {
    z-index: unset !important;
  }
`

const SiteLayout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Container>
        <Header />
        {children}
        <IntercomFacade>
          <Intercom color="#0005f4" />
        </IntercomFacade>
        <Footer />
      </Container>
    </>
  )
}

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SiteLayout
