import { navigate, useLocation } from "@reach/router"
import { parse } from "query-string"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import {
  DEFAULT_PURCHASE_TYPE,
  DEFAULT_SHIPPING_INTERVAL,
  PURCHASE_TYPE_SUBSCRIPTION,
} from "../../constants"
import { useStore } from "../../hooks/useStore"
import { useToggle } from "../../hooks/useToggle"
import { getAttributeByKey } from "../../utils/getLineItemAttributes"
import { getProductByStorefrontId } from "../../utils/getProductByStorefrontId"
import { getProductOptions } from "../../utils/getProductOptions"
import { getDiscountPriceRaw } from "../../utils/money"
import { parseGid } from "../../utils/parseGid"
import { variantForOptions } from "../../utils/variantsForOptions"
import ButtonAddToCart from "../Buttons/ButtonAddToCart"
import ModalSubscriptionCalculator from "../Modals/ModalSubscriptionCalculator"
import UpsellModal from "../UpsellModal/UpsellModal"
import ProductOptions from "./ProductOptions"
import PurchaseOptions from "./PurchaseOptions"
import {
  StyledBuyBox,
  StyledRatingsButton,
  StyledRatingsContainer,
} from "./StyledBuyBox"

const DEFAULT_PRODUCT_SIZE = "Large Bag (18lb)"

function handleClick() {
  document.getElementById("looxReviews").scrollIntoView({ behavior: "smooth" })
}

function BuyBoxFood({
  buttonText,
  children,
  showQuantity,
  discountCode,
  variant: DEFAULT_VARIANT,
}) {
  const location = useLocation()
  const { addedVariantIds, getVariantByGid, products } = useStore()

  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.src = "//loox.io/widget/loox.js?shop=wildearthpets.myshopify.com"

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  // gracefully handle legacy gutenberg redux api
  const product = getProductByStorefrontId(
    DEFAULT_VARIANT?.productId || DEFAULT_VARIANT?.product?.storefrontId,
    products
  )

  const availableOptions = getProductOptions(product)

  // tracking quantity here only serves one purpose
  // allow modals, controls, components, etc., outside of the ButtonAddToCart component to set the quantity
  const [quantity, setQuantity] = useState(1)

  const defaultOptions = location?.state?.options || {
    Size:
      DEFAULT_VARIANT?.label || DEFAULT_VARIANT?.title || DEFAULT_PRODUCT_SIZE,
  }

  const [options, setOptions] = useState(defaultOptions)
  const [purchaseType, setPurchaseType] = useState(DEFAULT_PURCHASE_TYPE)

  const onetimeVariant = variantForOptions(product, options)
  const subscriptionVariant = {
    // this metafield attached to the onetime variant of this product is automatically updated by ReCharge
    price: getAttributeByKey(onetimeVariant.metafields)(
      "discount_variant_price"
    ),
  }

  const [variant, setVariant] = useState(onetimeVariant)
  const [shippingInterval, setShippingInterval] = useState(
    DEFAULT_SHIPPING_INTERVAL
  )

  const [modalVisible, handleModalVisibility] = useToggle()

  useEffect(() => {
    setVariant(onetimeVariant)
  }, [onetimeVariant])

  useEffect(() => {
    const { variant: variantId } = parse(location.search)
    if (variantId) {
      const linkReferencedVariant = getVariantByGid(
        `gid://shopify/ProductVariant/${variantId}`,
        products
      )

      const formattedOptions = linkReferencedVariant.selectedOptions.reduce(
        (acc, option) => {
          if (!acc[option.name]) {
            acc[option.name] = option.value
          }

          return acc
        },
        {}
      )

      setOptions(formattedOptions)
      setVariant(linkReferencedVariant)
    }
  }, [location.search, products, getVariantByGid])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: product.title,
            brand: product.vendor,
            offers: [
              {
                priceCurrency: "USD",
                price: onetimeVariant.price,
                sku: onetimeVariant.sku,
                name: onetimeVariant.displayName,
                identifier: onetimeVariant.shopifyId,
              },
              {
                priceCurrency: "USD",
                price: discountCode
                  ? getDiscountPriceRaw(
                      subscriptionVariant.price,
                      discountCode.value
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : subscriptionVariant.price,
                sku: variant.sku,
                name: variant.displayName,
                identifier: `gid://shopify/ProductVariant/${getAttributeByKey(
                  onetimeVariant.metafields
                )("discount_variant_id")}`,
              },
            ],
          })}
        </script>
      </Helmet>
      {modalVisible && addedVariantIds?.length ? (
        <UpsellModal
          on={modalVisible}
          toggle={handleModalVisibility}
          variant={variant}
          purchaseType={purchaseType}
        />
      ) : null}
      <StyledBuyBox>
        <StyledRatingsContainer>
          <StyledRatingsButton onClick={handleClick}>
            <div
              className="loox-rating"
              data-fetch
              data-id="2495815024698"
              data-limit="10"
            ></div>
          </StyledRatingsButton>
        </StyledRatingsContainer>
        <ProductOptions
          options={options}
          availableOptions={availableOptions}
          handleChangeOption={e => {
            const { name, value } = e.target
            const selectedOptions = { ...options, [name]: value }
            const targetVariant = variantForOptions(product, selectedOptions)
            const params = new URLSearchParams({
              variant: parseGid(targetVariant.shopifyId),
            })
            navigate(`?${params.toString()}`)
            setOptions(selectedOptions)
            setVariant(targetVariant)
          }}
        />
        <PurchaseOptions
          purchaseType={purchaseType}
          handleChangePurchaseType={e => {
            setPurchaseType(e.target.value)
            setVariant(variantForOptions(product, options))
          }}
          shippingInterval={shippingInterval}
          handleChangeShippingInterval={e => {
            if (purchaseType === PURCHASE_TYPE_SUBSCRIPTION) {
              setShippingInterval(e.target.value)
            }
          }}
          subscriptionDiscount={discountCode}
          onetimeVariant={onetimeVariant}
          subscriptionVariant={subscriptionVariant}
        />
        {children}
        <ButtonAddToCart
          isSubscription={purchaseType === PURCHASE_TYPE_SUBSCRIPTION}
          showQuantity={showQuantity}
          text={buttonText}
          variant={variant}
          product={product}
          purchaseType={purchaseType}
          interval={shippingInterval}
          discountCode={discountCode}
          onAfterAction={handleModalVisibility}
          defaultQuantity={quantity}
          __dataLayer={{
            ProductTitle: product.title,
            ProductCategory: product.productType,
            ProductID: parseGid(product.shopifyId),
            ProductGID: product.shopifyId,
            PurchaseType: purchaseType,
            VariantName: variant.displayName,
            VariantID: parseGid(variant.shopifyId),
            VariantGID: variant.shopifyId,
            VariantSKU: variant.sku,
            VariantValue:
              purchaseType === PURCHASE_TYPE_SUBSCRIPTION
                ? subscriptionVariant.price
                : variant.price,

            product_ids: [parseGid(product.shopifyId)],
            product_type: "product_group",
            product_category: product.productType,
            product_name: variant.displayName,
            line_item_value:
              purchaseType === PURCHASE_TYPE_SUBSCRIPTION
                ? subscriptionVariant.price
                : variant.price,
          }}
        />
        <ModalSubscriptionCalculator
          handleChangeSubscription={(quantity, interval, size) => {
            setShippingInterval(interval)
            const selectedOptions = { ...options, Size: size }
            setOptions(selectedOptions)
            setQuantity(Number(quantity))
            setVariant(variantForOptions(product, selectedOptions))
          }}
        />
      </StyledBuyBox>
    </>
  )
}

export default BuyBoxFood
