import { navigate } from "@reach/router"
import React, { useState } from "react"
import { PURCHASE_TYPE_ONETIME } from "../../constants"
import { useStore } from "../../hooks/useStore"
import { getProductByStorefrontId } from "../../utils/getProductByStorefrontId"
import { getProductOptions } from "../../utils/getProductOptions"
import { parseGid } from "../../utils/parseGid"
import { variantForOptions } from "../../utils/variantsForOptions"
import ButtonAddToCart from "../Buttons/ButtonAddToCart"
import ClothingHeader from "./ClothingHeader"
import ProductOptions from "./ProductOptions"
import { StyledBuyBox } from "./StyledBuyBox"

function BuyBoxSnapback({
  buttonText,
  children,
  showQuantity,
  discountCode,
  variant: DEFAULT_VARIANT,
}) {
  const { products } = useStore()
  // gracefully handle legacy gutenberg redux api
  const product = getProductByStorefrontId(
    DEFAULT_VARIANT?.productId || DEFAULT_VARIANT?.product?.storefrontId,
    products
  )

  const availableOptions = getProductOptions(product)
  const [options, setOptions] = useState({ Color: "Black/ Grey" })

  const onetimeVariant = variantForOptions(product, options)
  const [variant, setVariant] = useState(onetimeVariant)

  return (
    <>
      <StyledBuyBox>
        <ClothingHeader displayName={product.title} price={variant.price} />
        <ProductOptions
          options={options}
          availableOptions={availableOptions}
          handleChangeOption={e => {
            const { name, value } = e.target
            const selectedOptions = { ...options, [name]: value }
            const targetVariant = variantForOptions(product, selectedOptions)
            const params = new URLSearchParams({
              variant: parseGid(targetVariant.shopifyId),
            })
            navigate(`?${params.toString()}`)

            setOptions(selectedOptions)
            setVariant(targetVariant)
          }}
        />
        {children}
        <ButtonAddToCart
          showQuantity={showQuantity}
          text={buttonText}
          afterAddedText="✔︎ Added"
          variant={variant}
          product={product}
          purchaseType={PURCHASE_TYPE_ONETIME}
          discountCode={discountCode}
          __dataLayer={{
            ProductTitle: product.title,
            ProductCategory: product.productType,
            ProductID: parseGid(product.shopifyId),
            ProductGID: product.shopifyId,
            PurchaseType: PURCHASE_TYPE_ONETIME,
            VariantName: variant.displayName,
            VariantID: parseGid(variant.shopifyId),
            VariantGID: variant.shopifyId,
            VariantSKU: variant.sku,
            VariantValue: variant.price,

            product_ids: [parseGid(product.shopifyId)],
            product_type: "product_group",
            product_category: product.productType,
            product_name: variant.displayName,
            line_item_value: variant.price,
          }}
        />
      </StyledBuyBox>
    </>
  )
}

export default BuyBoxSnapback
