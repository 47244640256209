import { em } from "polished"
import React from "react"
import styled from "styled-components"

const StyledColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(212px, 1fr));
  gap: ${({ desktopGap }) => desktopGap || em(24)};

  @media only screen and (max-width: 480px) {
    gap: ${({ mobileGap }) => mobileGap || em(24)};
    padding: ${({ customPadding }) => customPadding};
  }

  ${({ verticalAlignment }) =>
    verticalAlignment === "top"
      ? `align-items: start;`
      : verticalAlignment === "bottom"
      ? `align-items: end;`
      : verticalAlignment === "center" && `align-items: center;`}

  > img {
    object-fit: contain !important;
  }
`

function Columns({
  children,
  verticalAlignment,
  mobileGap,
  desktopGap,
  customPadding,
}) {
  return (
    <StyledColumns
      verticalAlignment={verticalAlignment}
      mobileGap={mobileGap}
      customPadding={customPadding}
      desktopGap={desktopGap}
    >
      {children}
    </StyledColumns>
  )
}

export default Columns
